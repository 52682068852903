/* /src/login/login.css */
body, html {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    background-color: #000;
    color: #fff;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.login-container {
    width: 100%;
    max-width: 400px;
    padding: 20px;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 10px;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.5);
    text-align: center;
}

.login-title {
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: 700;
}

.input-container {
    margin-bottom: 15px;
    text-align: left;
}

.input-container label {
    display: block;
    font-size: 14px;
    margin-bottom: 5px;
}

.input-container input {
    width: 90%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #333;
    border-radius: 5px;
    background-color: #111;
    color: #fff;
    transition: border 0.3s ease;
}

.input-container input:focus {
    border-color: #444;
    outline: none;
}

.success-message {
    color: #28a745;
    font-size: 14px;
    margin-bottom: 15px;
}

.error-message {
    color: #ff6b6b;
    font-size: 14px;
    margin-bottom: 15px;
}

.login-button {
    width: 90%;
    padding: 12px;
    background-color: #444;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s ease;
}

.login-button:hover {
    background-color: #555;
}

.login-button:active {
    background-color: #666;
}

.login-small-text {
    margin-top: 20px;
    font-size: 14px;
    color: #888;
}

.login-small-text a {
    color: #888;
    text-decoration: none;
    transition: color 0.3s ease;
}

.login-small-text a:hover {
    color: #aaa;
}

.register-link {
    font-weight: bold;
    color: #aaa;
    cursor: pointer;
    transition: color 0.3s ease;
}

.register-link:hover {
    color: #fff;
}

@media (max-width: 768px) {
    .login-container {
        padding: 15px;
    }

    .login-title {
        font-size: 20px;
    }

    .input-container input {
        font-size: 14px;
    }

    .login-button {
        font-size: 14px;
    }
}

/* /src/sidebar/sidebar.css */
.sidebar {
    width: 300px;
    background-color: #1c1c1e;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    height: 100vh;
    padding: 16px;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
}

.sidebar-header {
    display: flex;
    flex-direction: column; 
    gap: 10px;
    margin-bottom: 20px;
}

.header-button {
    background-color: #333333;
    color: #ffffff;
    padding: 12px; 
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
    text-align: center;
    width: 100%;
}

.header-button:hover {
    background-color: #555555;
}

.chat-list {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    margin-top: 20px; 
}

.chat-item {
    display: flex;
    align-items: center;
    padding: 10px;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-bottom: 10px;
}

.chat-item:hover {
    background-color: #333333;
}

.chat-pfp {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    margin-right: 12px;
}

/* Chat information */
.chat-info {
    display: flex;
    flex-direction: column;
}

.chat-name {
    font-weight: 600;
    font-size: 16px;
}

.chat-last-message {
    font-size: 14px;
    color: #a0a0a0;
}

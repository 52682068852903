/* /src/register/register.css */
@import '../login/login.css';

.register-container {
    width: 100%;
    max-width: 400px;
    padding: 20px;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 10px;
    box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.5);
    text-align: center;
}

.register-title {
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: 700;
}

.input-container {
    margin-bottom: 15px;
    text-align: left;
}

.input-container label {
    display: block;
    font-size: 14px;
    margin-bottom: 5px;
}

.input-container input {
    width: 90%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #333;
    border-radius: 5px;
    background-color: #111;
    color: #fff;
    transition: border 0.3s ease;
}

.input-container input:focus {
    border-color: #444;
    outline: none;
}

.success-message {
    color: #28a745;
    font-size: 14px;
    margin-bottom: 15px;
}

.error-message {
    color: #ff6b6b;
    font-size: 14px;
    margin-bottom: 15px;
}

.register-button {
    width: 90%;
    padding: 12px;
    background-color: #444;
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s ease;
}

.register-button:hover {
    background-color: #555;
}

.register-button:active {
    background-color: #666;
}
